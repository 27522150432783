import * as actionTypes from '../constants/ActionType'
import {Map} from 'immutable'

const baseInit = Map({
  state: 4,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  user: null,
  needPull: false
});

const ListInit = Map({
  state: 4,
  param: null,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  list: null,
  totalPage: 1,
  totalNumber: 0,
  currentPage: 1,
  countPerPage: 10,
  cache: null,
  needPull: false
});

const errorState = Map({
  flag: false,
  message: null,
  errorCode: 0,
  title: null,
  confirm: null,
  cancel: null,
  errorType: null,
})
export const  error = (state = errorState, action) => {
  const { type, error } = action;
  if (type === actionTypes.RESET_ERROR_MESSAGE) {
    if (!state.get('flag')) {
      return state.merge({
        flag: action.flag,
        message: action.message,
        errorCode: action.errorCode,
        title: action.title || null,
        confirm: action.confirm || null,
        cancel: action.cancel || null,
        onConfirm: action.onConfirm || null,
        onClose: action.onClose || null,
        shadow: action.shadow || null,
        alertType: action.alertType || null
      })
    } else {
      return state;
    }
  } else if (type === actionTypes.RESET_ERROR) {
    return errorState;
  } else if (error) {
    if (!state.get('flag')) {
      return state.merge({
        flag: false,
        message: action.error.message,
        errorCode: action.error.errorCode
      })
    }
  }
  return state
}

export const phoneCode = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypes.GET_PHONE_CODE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_PHONE_CODE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypes.GET_PHONE_CODE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_PHONE_CODE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_PHONE_CODE_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const cloudSTS = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypes.GET_CLOUD_STS_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_CLOUD_STS_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypes.GET_CLOUD_STS_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_CLOUD_STS_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_CLOUD_STS_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const areaListData = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_AREA_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_AREA_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          list: {...state.get('list'), ['code-' + action.param.id]: action.response},
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypes.GET_AREA_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_AREA_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_AREA_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}
