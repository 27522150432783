/*
* list
* */
export const GET_POST_LIST_LOAD = 'GET_POST_LIST_LOAD';
export const GET_POST_LIST_REQUEST = 'GET_POST_LIST_REQUEST';
export const GET_POST_LIST_SUCCESS = 'GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAILURE = 'GET_POST_LIST_FAILURE';
export const GET_POST_LIST_UPDATE = 'GET_POST_LIST_UPDATE';
export const GET_POST_LIST_RESET = 'GET_POST_LIST_RESET';
/*
* list
* */
export const GET_USER_POST_LIST_LOAD = 'GET_USER_POST_LIST_LOAD';
export const GET_USER_POST_LIST_REQUEST = 'GET_USER_POST_LIST_REQUEST';
export const GET_USER_POST_LIST_SUCCESS = 'GET_USER_POST_LIST_SUCCESS';
export const GET_USER_POST_LIST_FAILURE = 'GET_USER_POST_LIST_FAILURE';
export const GET_USER_POST_LIST_UPDATE = 'GET_USER_POST_LIST_UPDATE';
export const GET_USER_POST_LIST_RESET = 'GET_USER_POST_LIST_RESET';
/*
* list
* */
export const GET_USER_FOLLOW_POST_LIST_LOAD = 'GET_USER_FOLLOW_POST_LIST_LOAD';
export const GET_USER_FOLLOW_POST_LIST_REQUEST = 'GET_USER_FOLLOW_POST_LIST_REQUEST';
export const GET_USER_FOLLOW_POST_LIST_SUCCESS = 'GET_USER_FOLLOW_POST_LIST_SUCCESS';
export const GET_USER_FOLLOW_POST_LIST_FAILURE = 'GET_USER_FOLLOW_POST_LIST_FAILURE';
export const GET_USER_FOLLOW_POST_LIST_UPDATE = 'GET_USER_FOLLOW_POST_LIST_UPDATE';
export const GET_USER_FOLLOW_POST_LIST_RESET = 'GET_USER_FOLLOW_POST_LIST_RESET';
/*
* detail
* */
export const GET_POST_DETAIL_LOAD = 'GET_POST_DETAIL_LOAD';
export const GET_POST_DETAIL_REQUEST = 'GET_POST_DETAIL_REQUEST';
export const GET_POST_DETAIL_SUCCESS = 'GET_POST_DETAIL_SUCCESS';
export const GET_POST_DETAIL_FAILURE = 'GET_POST_DETAIL_FAILURE';
export const GET_POST_DETAIL_UPDATE = 'GET_POST_DETAIL_UPDATE';
export const GET_POST_DETAIL_RESET = 'GET_POST_DETAIL_RESET';
/*
* post Like
* */
export const ADD_POST_LIKE_LOAD = 'ADD_POST_LIKE_LOAD';
export const POST_OTHER_CHANGE_REQUEST = 'POST_OTHER_CHANGE_REQUEST';
export const POST_OTHER_CHANGE_SUCCESS = 'POST_OTHER_CHANGE_SUCCESS';
export const POST_OTHER_CHANGE_FAILURE = 'POST_OTHER_CHANGE_FAILURE';
export const POST_OTHER_CHANGE_UPDATE = 'POST_OTHER_CHANGE_UPDATE';
export const POST_OTHER_CHANGE_RESET = 'POST_OTHER_CHANGE_RESET';


/*
* post Like
* */
export const ADD_POST_COLLECT_LOAD = 'ADD_POST_COLLECT_LOAD';

/*
* add comment
* */
export const ADD_POST_COMMENT_LOAD = 'ADD_POST_COMMENT_LOAD';
/*
* add comment
* */
export const DELETE_POST_COMMENT_LOAD = 'DELETE_POST_COMMENT_LOAD';

/*
* share add
* */
export const ADD_POST_SHARE_LOAD = 'ADD_POST_SHARE_LOAD';

/*
* post comment list
* */
export const GET_POST_COMMENT_LIST_LOAD = 'GET_POST_COMMENT_LIST_LOAD';
export const GET_POST_COMMENT_LIST_REQUEST = 'GET_POST_COMMENT_LIST_REQUEST';
export const GET_POST_COMMENT_LIST_SUCCESS = 'GET_POST_COMMENT_LIST_SUCCESS';
export const GET_POST_COMMENT_LIST_FAILURE = 'GET_POST_COMMENT_LIST_FAILURE';
export const GET_POST_COMMENT_LIST_UPDATE = 'GET_POST_COMMENT_LIST_UPDATE';
export const GET_POST_COMMENT_LIST_RESET = 'GET_POST_COMMENT_LIST_RESET';
