/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react'

// You can delete this file if you're not using it
import WrapRootElement from "./src/redux/reduxWrapper"

export const wrapRootElement = ({ element }) => (
  <WrapRootElement element={element} />
)
