exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activity-js": () => import("./../../../src/pages/activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-explore-details-[id]-js": () => import("./../../../src/pages/explore/details/[id].js" /* webpackChunkName: "component---src-pages-explore-details-[id]-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-explore-post-js": () => import("./../../../src/pages/explore/post.js" /* webpackChunkName: "component---src-pages-explore-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-register-js": () => import("./../../../src/pages/login/register.js" /* webpackChunkName: "component---src-pages-login-register-js" */),
  "component---src-pages-market-js": () => import("./../../../src/pages/market.js" /* webpackChunkName: "component---src-pages-market-js" */),
  "component---src-pages-my-js": () => import("./../../../src/pages/my/[...].js" /* webpackChunkName: "component---src-pages-my-js" */),
  "component---src-pages-notice-js": () => import("./../../../src/pages/notice.js" /* webpackChunkName: "component---src-pages-notice-js" */),
  "component---src-pages-order-item-id-js": () => import("./../../../src/pages/order/[itemId].js" /* webpackChunkName: "component---src-pages-order-item-id-js" */),
  "component---src-pages-order-payment-order-id-js": () => import("./../../../src/pages/order/payment/[orderId].js" /* webpackChunkName: "component---src-pages-order-payment-order-id-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-protocols-about-js": () => import("./../../../src/pages/protocols/about.js" /* webpackChunkName: "component---src-pages-protocols-about-js" */),
  "component---src-pages-protocols-about-path-[name]-js": () => import("./../../../src/pages/protocols/[aboutPath]/[name].js" /* webpackChunkName: "component---src-pages-protocols-about-path-[name]-js" */),
  "component---src-pages-protocols-app-js": () => import("./../../../src/pages/protocols/app.js" /* webpackChunkName: "component---src-pages-protocols-app-js" */),
  "component---src-pages-protocols-art-license-js": () => import("./../../../src/pages/protocols/art-license.js" /* webpackChunkName: "component---src-pages-protocols-art-license-js" */),
  "component---src-pages-protocols-help-aq-js": () => import("./../../../src/pages/protocols/help/aq.js" /* webpackChunkName: "component---src-pages-protocols-help-aq-js" */),
  "component---src-pages-protocols-license-js": () => import("./../../../src/pages/protocols/license.js" /* webpackChunkName: "component---src-pages-protocols-license-js" */),
  "component---src-pages-protocols-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/protocols/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-protocols-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-protocols-news-js": () => import("./../../../src/pages/protocols/news.js" /* webpackChunkName: "component---src-pages-protocols-news-js" */),
  "component---src-pages-protocols-notice-js": () => import("./../../../src/pages/protocols/notice.js" /* webpackChunkName: "component---src-pages-protocols-notice-js" */),
  "component---src-pages-protocols-show-js": () => import("./../../../src/pages/protocols/show.js" /* webpackChunkName: "component---src-pages-protocols-show-js" */),
  "component---src-pages-shop-item-item-id-js": () => import("./../../../src/pages/shop/item/[itemId].js" /* webpackChunkName: "component---src-pages-shop-item-item-id-js" */),
  "component---src-pages-shop-shop-id-js": () => import("./../../../src/pages/shop/[shopId].js" /* webpackChunkName: "component---src-pages-shop-shop-id-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-profile-user-id-js": () => import("./../../../src/pages/user/profile/[userId].js" /* webpackChunkName: "component---src-pages-user-profile-user-id-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-village-js": () => import("./../../../src/pages/village.js" /* webpackChunkName: "component---src-pages-village-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

