import defaultConfig from './dev'

const config = {
  // extend and override dev config here.
  DEV: false,
  APP_VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  API_ROOT: process.env.REACT_APP_API_ROOT || 'https://api.yimaiart.com/api/',
  APP_FILE_URL: process.env.REACT_APP_FILE_ROOT || 'https://files.yimaiart.com/',
}

export default { ...defaultConfig, ...config }
