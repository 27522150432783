import * as ActionTypesRelation from '../constants/ActionTypesRelation'
import {Map} from 'immutable'

const baseInit = Map({
  state: 4,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  user: null,
  needPull: false
});

const ListInit = Map({
  state: 4,
  param: null,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  list: null,
  totalPage: 1,
  totalNumber: 0,
  currentPage: 1,
  countPerPage: 10,
  cache: null,
  needPull: false
});



export const fansList = (state = ListInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.GET_ATTENTION_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.GET_ATTENTION_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.GET_ATTENTION_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.GET_ATTENTION_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.GET_ATTENTION_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}



export const followList = (state = ListInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.GET_FOLLOW_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.GET_FOLLOW_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.GET_FOLLOW_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.GET_FOLLOW_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.GET_FOLLOW_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}



export const attentionChange = (state = baseInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.FOLLOW_ADD_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.FOLLOW_ADD_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.FOLLOW_ADD_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.FOLLOW_ADD_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.FOLLOW_ADD_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}



export const collectList = (state = ListInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.GET_COLLECT_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.GET_COLLECT_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.GET_COLLECT_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.GET_COLLECT_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.GET_COLLECT_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const collectChange = (state = baseInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.COLLECT_CHANGE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.COLLECT_CHANGE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.COLLECT_CHANGE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.COLLECT_CHANGE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.COLLECT_CHANGE_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}



export const suggestUser = (state = ListInit, action) => {
  switch (action.type) {
    case ActionTypesRelation.GET_SUGGEST_USET_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case ActionTypesRelation.GET_SUGGEST_USET_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case ActionTypesRelation.GET_SUGGEST_USET_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case ActionTypesRelation.GET_SUGGEST_USET_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case ActionTypesRelation.GET_SUGGEST_USET_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}
