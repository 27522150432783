import * as actionTypesOrder from '../constants/ActionTypesOrder'
import {Map} from 'immutable'

const baseInit = Map({
  state: 4,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  user: null,
  needPull: false
});

const ListInit = Map({
  state: 4,
  param: null,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  list: null,
  totalPage: 1,
  totalNumber: 0,
  currentPage: 1,
  countPerPage: 10,
  cache: null,
  needPull: false
});

export const orderList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypesOrder.GET_ORDER_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.GET_ORDER_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.GET_ORDER_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const orderInfo = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesOrder.GET_ORDER_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.GET_ORDER_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.GET_ORDER_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const orderAdd = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesOrder.ORDER_ADD_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.ORDER_ADD_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.ORDER_ADD_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.ORDER_ADD_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.ORDER_ADD_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const orderModify = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesOrder.ORDER_MODIFY_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.ORDER_MODIFY_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.ORDER_MODIFY_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.ORDER_MODIFY_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.ORDER_MODIFY_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}



export const payLink = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesOrder.GET_ORDER_PAYMENT_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.GET_ORDER_PAYMENT_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_PAYMENT_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_PAYMENT_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.GET_ORDER_PAYMENT_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}





export const orderBill = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesOrder.GET_ORDER_BILL_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesOrder.GET_ORDER_BILL_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_BILL_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesOrder.GET_ORDER_BILL_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesOrder.GET_ORDER_BILL_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

