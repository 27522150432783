/*
* 获取订单列表
* */
export const GET_ORDER_LIST_LOAD = 'GET_ORDER_LIST_LOAD';
export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAILURE = 'GET_ORDER_LIST_FAILURE';
export const GET_ORDER_LIST_UPDATE = 'GET_ORDER_LIST_UPDATE';
export const GET_ORDER_LIST_RESET = 'GET_ORDER_LIST_RESET';


/*
* 获取订单信息
* */
export const GET_ORDER_LOAD = 'GET_ORDER_LOAD';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDER_UPDATE = 'GET_ORDER_UPDATE';
export const GET_ORDER_RESET = 'GET_ORDER_RESET';


/*
* 新增订单
* */
export const ORDER_ADD_LOAD = 'ORDER_ADD_LOAD';
export const ORDER_ADD_REQUEST = 'ORDER_ADD_REQUEST';
export const ORDER_ADD_SUCCESS = 'ORDER_ADD_SUCCESS';
export const ORDER_ADD_FAILURE = 'ORDER_ADD_FAILURE';
export const ORDER_ADD_UPDATE = 'ORDER_ADD_UPDATE';
export const ORDER_ADD_RESET = 'ORDER_ADD_RESET';

/*
* 更新订单
* */
export const ORDER_MODIFY_LOAD = 'ORDER_MODIFY_LOAD';
export const ORDER_MODIFY_REQUEST = 'ORDER_MODIFY_REQUEST';
export const ORDER_MODIFY_SUCCESS = 'ORDER_MODIFY_SUCCESS';
export const ORDER_MODIFY_FAILURE = 'ORDER_MODIFY_FAILURE';
export const ORDER_MODIFY_UPDATE = 'ORDER_MODIFY_UPDATE';
export const ORDER_MODIFY_RESET = 'ORDER_MODIFY_RESET';


/*
* 订单退款信息
* */
export const ORDER_REFUND_LOAD = 'ORDER_REFUND_LOAD';
export const ORDER_REFUND_REQUEST = 'ORDER_REFUND_REQUEST';
export const ORDER_REFUND_SUCCESS = 'ORDER_REFUND_SUCCESS';
export const ORDER_REFUND_FAILURE = 'ORDER_REFUND_FAILURE';
export const ORDER_REFUND_UPDATE = 'ORDER_REFUND_UPDATE';
export const ORDER_REFUND_RESET = 'ORDER_REFUND_RESET';


/*
* 获取订单支付信息
* */
export const GET_ORDER_PAYMENT_LOAD = 'GET_ORDER_PAYMENT_LOAD';
export const GET_ORDER_PAYMENT_REQUEST = 'GET_ORDER_PAYMENT_REQUEST';
export const GET_ORDER_PAYMENT_SUCCESS = 'GET_ORDER_PAYMENT_SUCCESS';
export const GET_ORDER_PAYMENT_FAILURE = 'GET_ORDER_PAYMENT_FAILURE';
export const GET_ORDER_PAYMENT_UPDATE = 'GET_ORDER_PAYMENT_UPDATE';
export const GET_ORDER_PAYMENT_RESET = 'GET_ORDER_PAYMENT_RESET';



/*
* 获取订单支付信息
* */
export const GET_ORDER_BILL_LOAD = 'GET_ORDER_BILL_LOAD';
export const GET_ORDER_BILL_REQUEST = 'GET_ORDER_BILL_REQUEST';
export const GET_ORDER_BILL_SUCCESS = 'GET_ORDER_BILL_SUCCESS';
export const GET_ORDER_BILL_FAILURE = 'GET_ORDER_BILL_FAILURE';
export const GET_ORDER_BILL_UPDATE = 'GET_ORDER_BILL_UPDATE';
export const GET_ORDER_BILL_RESET = 'GET_ORDER_BILL_RESET';


