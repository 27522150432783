

/*
* 获取店铺列表
* */
export const GET_SHOP_LIST_LOAD = 'GET_SHOP_LIST_LOAD';
export const GET_SHOP_LIST_REQUEST = 'GET_SHOP_LIST_REQUEST';
export const GET_SHOP_LIST_SUCCESS = 'GET_SHOP_LIST_SUCCESS';
export const GET_SHOP_LIST_FAILURE = 'GET_SHOP_LIST_FAILURE';
export const GET_SHOP_LIST_UPDATE = 'GET_SHOP_LIST_UPDATE';
export const GET_SHOP_LIST_RESET = 'GET_SHOP_LIST_RESET';


/*
* 获取店铺信息
* */
export const GET_SHOP_LOAD = 'GET_SHOP_LOAD';
export const GET_SHOP_REQUEST = 'GET_SHOP_REQUEST';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE';
export const GET_SHOP_UPDATE = 'GET_SHOP_UPDATE';
export const GET_SHOP_RESET = 'GET_SHOP_RESET';

/*
* 新增更新店铺
* */
export const SHOP_MODIFY_LOAD = 'SHOP_MODIFY_LOAD';
export const SHOP_MODIFY_REQUEST = 'SHOP_MODIFY_REQUEST';
export const SHOP_MODIFY_SUCCESS = 'SHOP_MODIFY_SUCCESS';
export const SHOP_MODIFY_FAILURE = 'SHOP_MODIFY_FAILURE';
export const SHOP_MODIFY_UPDATE = 'SHOP_MODIFY_UPDATE';
export const SHOP_MODIFY_RESET = 'SHOP_MODIFY_RESET';


/*
* 店铺商品列表
* */
export const SHOP_ITEM_LIST_LOAD = 'SHOP_ITEM_LIST_LOAD';
export const SHOP_ITEM_LIST_REQUEST = 'SHOP_ITEM_LIST_REQUEST';
export const SHOP_ITEM_LIST_SUCCESS = 'SHOP_ITEM_LIST_SUCCESS';
export const SHOP_ITEM_LIST_FAILURE = 'SHOP_ITEM_LIST_FAILURE';
export const SHOP_ITEM_LIST_UPDATE = 'SHOP_ITEM_LIST_UPDATE';
export const SHOP_ITEM_LIST_RESET = 'SHOP_ITEM_LIST_RESET';


/*
* 店铺商品详情
* */
export const SHOP_ITEM_DETAIL_LOAD = 'SHOP_ITEM_DETAIL_LOAD';
export const SHOP_ITEM_DETAIL_REQUEST = 'SHOP_ITEM_DETAIL_REQUEST';
export const SHOP_ITEM_DETAIL_SUCCESS = 'SHOP_ITEM_DETAIL_SUCCESS';
export const SHOP_ITEM_DETAIL_FAILURE = 'SHOP_ITEM_DETAIL_FAILURE';
export const SHOP_ITEM_DETAIL_UPDATE = 'SHOP_ITEM_DETAIL_UPDATE';
export const SHOP_ITEM_DETAIL_RESET = 'SHOP_ITEM_DETAIL_RESET';

/*
* 新增商品
* */
export const SHOP_ITEM_ADD_LOAD = 'SHOP_ITEM_ADD_LOAD';
export const SHOP_ITEM_ADD_REQUEST = 'SHOP_ITEM_ADD_REQUEST';
export const SHOP_ITEM_ADD_SUCCESS = 'SHOP_ITEM_ADD_SUCCESS';
export const SHOP_ITEM_ADD_FAILURE = 'SHOP_ITEM_ADD_FAILURE';
export const SHOP_ITEM_ADD_UPDATE = 'SHOP_ITEM_ADD_UPDATE';
export const SHOP_ITEM_ADD_RESET = 'SHOP_ITEM_ADD_RESET';

/*
* 修改商品
* */
export const SHOP_ITEM_MODIFY_LOAD = 'SHOP_ITEM_MODIFY_LOAD';
export const SHOP_ITEM_MODIFY_REQUEST = 'SHOP_ITEM_MODIFY_REQUEST';
export const SHOP_ITEM_MODIFY_SUCCESS = 'SHOP_ITEM_MODIFY_SUCCESS';
export const SHOP_ITEM_MODIFY_FAILURE = 'SHOP_ITEM_MODIFY_FAILURE';
export const SHOP_ITEM_MODIFY_UPDATE = 'SHOP_ITEM_MODIFY_UPDATE';
export const SHOP_ITEM_MODIFY_RESET = 'SHOP_ITEM_MODIFY_RESET';



/*
* 获取商品类目
* */
export const SHOP_ITEM_CATEGORY_LOAD = 'SHOP_ITEM_CATEGORY_LOAD';
export const SHOP_ITEM_CATEGORY_REQUEST = 'SHOP_ITEM_CATEGORY_REQUEST';
export const SHOP_ITEM_CATEGORY_SUCCESS = 'SHOP_ITEM_CATEGORY_SUCCESS';
export const SHOP_ITEM_CATEGORY_FAILURE = 'SHOP_ITEM_CATEGORY_FAILURE';
export const SHOP_ITEM_CATEGORY_UPDATE = 'SHOP_ITEM_CATEGORY_UPDATE';
export const SHOP_ITEM_CATEGORY_RESET = 'SHOP_ITEM_CATEGORY_RESET';

