

/*
* 获取首页商品TAB
* */
export const MARKET_TAB_LIST_LOAD = 'MARKET_TABLIST_LOAD';
export const MARKET_TAB_LIST_REQUEST = 'MARKET_TAB_LIST_REQUEST';
export const MARKET_TAB_LIST_SUCCESS = 'MARKET_TAB_LIST_SUCCESS';
export const MARKET_TAB_LIST_FAILURE = 'MARKET_TAB_LIST_FAILURE';
export const MARKET_TAB_LIST_UPDATE = 'MARKET_TAB_LIST_UPDATE';
export const MARKET_TAB_LIST_RESET = 'MARKET_TAB_LIST_RESET';

/*
* 获取首页商品列表
* */
export const MARKET_ITEM_LIST_LOAD = 'MARKET_ITEM_LIST_LOAD';
export const MARKET_ITEM_LIST_REQUEST = 'MARKET_ITEM_LIST_REQUEST';
export const MARKET_ITEM_LIST_SUCCESS = 'MARKET_ITEM_LIST_SUCCESS';
export const MARKET_ITEM_LIST_FAILURE = 'MARKET_ITEM_LIST_FAILURE';
export const MARKET_ITEM_LIST_UPDATE = 'MARKET_ITEM_LIST_UPDATE';
export const MARKET_ITEM_LIST_RESET = 'MARKET_ITEM_LIST_RESET';

/*
* 获取首页商品列表
* */
export const MARKET_ITEM_HISTORY_LIST_LOAD = 'MARKET_ITEM_HISTORY_LIST_LOAD';
export const MARKET_ITEM_HISTORY_LIST_REQUEST = 'MARKET_ITEM_HISTORY_LIST_REQUEST';
export const MARKET_ITEM_HISTORY_LIST_SUCCESS = 'MARKET_ITEM_HISTORY_LIST_SUCCESS';
export const MARKET_ITEM_HISTORY_LIST_FAILURE = 'MARKET_ITEM_HISTORY_LIST_FAILURE';
export const MARKET_ITEM_HISTORY_LIST_UPDATE = 'MARKET_ITEM_HISTORY_LIST_UPDATE';
export const MARKET_ITEM_HISTORY_LIST_RESET = 'MARKET_ITEM_HISTORY_LIST_RESET';

/*
* 店铺商品列表
* */
export const SHOP_ITEM_LIST_LOAD = 'SHOP_ITEM_LIST_LOAD';
export const SHOP_ITEM_LIST_REQUEST = 'SHOP_ITEM_LIST_REQUEST';
export const SHOP_ITEM_LIST_SUCCESS = 'SHOP_ITEM_LIST_SUCCESS';
export const SHOP_ITEM_LIST_FAILURE = 'SHOP_ITEM_LIST_FAILURE';
export const SHOP_ITEM_LIST_UPDATE = 'SHOP_ITEM_LIST_UPDATE';
export const SHOP_ITEM_LIST_RESET = 'SHOP_ITEM_LIST_RESET';

