/**
 * Created by luzq on 16/6/5.
 */
export function isType(type) {
  return function (obj) {
    return {}.toString.call(obj) === "[object " + type + "]"
  }
}
export const isObject = isType('Object');
export const isString = isType('String');
export const isArray = isType('Array');
export const isFunction = isType('Function');


export function requestAnimFrame() {
  window.requestAnimFrame = (function() {
    return  window.requestAnimationFrame       ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame    ||
      function( callback ){
        window.setTimeout(callback, 1000 / 60);
      };
  })();
  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
  }
};

export function getUrl(hrf) {
  let vars = [],
    hash;
  let locationHref = !hrf ? window.location.href : hrf;
  if (locationHref.indexOf('%') > 0) {
    locationHref = unescape(locationHref)
  }
  if(locationHref.indexOf('?') < 0) {
    return [];
  }
  const hashes = locationHref.slice(locationHref.indexOf('?') + 1).split('&');
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}
export const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export function getImg(url) {
  return new Promise(function(resolve, reject) {
    var img = new Image();

    img.onload = function() {
      resolve(this);
    };

    img.onerror = function(err) {
      resolve(null);
    };
    img.src = url;
  });
}
export const base64ToBlob = (urlData, type) => {
  const arr = urlData.split(',');
  const mime = arr[0].match(/:(.*?);/)[1] || type;
// 去掉url的头，并转化为byte
  const bytes = window.atob(arr[1]);
// 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
// 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], {
    type: mime
  });
}
