/*
 * 获取推荐用户列表信息
 * */

export const GET_SUGGEST_USET_LIST_LOAD = 'GET_SUGGEST_USET_LIST_LOAD';
export const GET_SUGGEST_USET_LIST_REQUEST = 'GET_SUGGEST_USET_LIST_REQUEST';
export const GET_SUGGEST_USET_LIST_SUCCESS = 'GET_SUGGEST_USET_LIST_SUCCESS';
export const GET_SUGGEST_USET_LIST_FAILURE = 'GET_SUGGEST_USET_LIST_FAILURE';
export const GET_SUGGEST_USET_LIST_UPDATE = 'GET_SUGGEST_USET_LIST_UPDATE';
export const GET_SUGGEST_USET_LIST_RESET = 'GET_SUGGEST_USET_LIST_RESET';

/*
 * 获取fans列表信息
 * */

export const GET_ATTENTION_LIST_LOAD = 'GET_ATTENTION_LIST_LOAD';
export const GET_ATTENTION_LIST_REQUEST = 'GET_ATTENTION_LIST_REQUEST';
export const GET_ATTENTION_LIST_SUCCESS = 'GET_ATTENTION_LIST_SUCCESS';
export const GET_ATTENTION_LIST_FAILURE = 'GET_ATTENTION_LIST_FAILURE';
export const GET_ATTENTION_LIST_UPDATE = 'GET_ATTENTION_LIST_UPDATE';
export const GET_ATTENTION_LIST_RESET = 'GET_ATTENTION_LIST_RESET';


/*
* 获取关注列表信息
* */
export const GET_FOLLOW_LIST_LOAD = 'GET_FOLLOW_LIST_LOAD';
export const GET_FOLLOW_LIST_REQUEST = 'GET_FOLLOW_LIST_REQUEST';
export const GET_FOLLOW_LIST_SUCCESS = 'GET_FOLLOW_LIST_SUCCESS';
export const GET_FOLLOW_LIST_FAILURE = 'GET_FOLLOW_LIST_FAILURE';
export const GET_FOLLOW_LIST_UPDATE = 'GET_FOLLOW_LIST_UPDATE';
export const GET_FOLLOW_LIST_RESET = 'GET_FOLLOW_LIST_RESET';

/*
* 新增关注
* */
export const FOLLOW_ADD_LOAD = 'FOLLOW_ADD_LOAD';
export const FOLLOW_ADD_REQUEST = 'FOLLOW_ADD_REQUEST';
export const FOLLOW_ADD_SUCCESS = 'FOLLOW_ADD_SUCCESS';
export const FOLLOW_ADD_FAILURE = 'FOLLOW_ADD_FAILURE';
export const FOLLOW_ADD_UPDATE = 'FOLLOW_ADD_UPDATE';
export const FOLLOW_ADD_RESET = 'FOLLOW_ADD_RESET';

/*
* 取消关注
* */
export const FOLLOW_DEL_LOAD = 'FOLLOW_DEL_LOAD';
export const FOLLOW_DEL_REQUEST = 'FOLLOW_DEL_REQUEST';
export const FOLLOW_DEL_SUCCESS = 'FOLLOW_DEL_SUCCESS';
export const FOLLOW_DEL_FAILURE = 'FOLLOW_DEL_FAILURE';
export const FOLLOW_DEL_UPDATE = 'FOLLOW_DEL_UPDATE';
export const FOLLOW_DEL_RESET = 'FOLLOW_DEL_RESET';

/*
* 收藏
* */

/*
* 获取关注列表信息
* */
export const GET_COLLECT_LIST_LOAD = 'GET_COLLECT_LIST_LOAD';
export const GET_COLLECT_LIST_REQUEST = 'GET_COLLECT_LIST_REQUEST';
export const GET_COLLECT_LIST_SUCCESS = 'GET_COLLECT_LIST_SUCCESS';
export const GET_COLLECT_LIST_FAILURE = 'GET_COLLECT_LIST_FAILURE';
export const GET_COLLECT_LIST_UPDATE = 'GET_COLLECT_LIST_UPDATE';
export const GET_COLLECT_LIST_RESET = 'GET_COLLECT_LIST_RESET';

/*
* 新增收藏
* */
export const COLLECT_ADD_LOAD = 'COLLECT_ADD_LOAD';
export const COLLECT_CHANGE_REQUEST = 'COLLECT_CHANGE_REQUEST';
export const COLLECT_CHANGE_SUCCESS = 'COLLECT_CHANGE_SUCCESS';
export const COLLECT_CHANGE_FAILURE = 'COLLECT_CHANGE_FAILURE';
export const COLLECT_CHANGE_UPDATE = 'COLLECT_CHANGE_UPDATE';
export const COLLECT_CHANGE_RESET = 'COLLECT_CHANGE_RESET';

/*
* 删除收藏
* */
export const COLLECT_DEL_LOAD = 'COLLECT_DEL_LOAD';
