import * as actionTypesPost from '../constants/ActionTypesPost'
import {Map} from 'immutable'

const baseInit = Map({
  state: 4,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  user: null,
  needPull: false
});

const ListInit = Map({
  state: 4,
  param: null,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  list: null,
  totalPage: 1,
  totalNumber: 0,
  currentPage: 1,
  countPerPage: 10,
  cache: null,
  needPull: false
});

export const postList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypesPost.GET_POST_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.GET_POST_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.GET_POST_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.GET_POST_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.GET_POST_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const userPostList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypesPost.GET_USER_POST_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.GET_USER_POST_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.GET_USER_POST_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.GET_USER_POST_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.GET_USER_POST_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const userFollowPostList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypesPost.GET_USER_FOLLOW_POST_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.GET_USER_FOLLOW_POST_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.GET_USER_FOLLOW_POST_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.GET_USER_FOLLOW_POST_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.GET_USER_FOLLOW_POST_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const postDetail = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesPost.GET_POST_DETAIL_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.GET_POST_DETAIL_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.GET_POST_DETAIL_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.GET_POST_DETAIL_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.GET_POST_DETAIL_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const postCommentList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypesPost.GET_POST_COMMENT_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.GET_POST_COMMENT_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.GET_POST_COMMENT_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.GET_POST_COMMENT_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.GET_POST_COMMENT_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const postChange = (state = baseInit, action) => {
  switch (action.type) {
    case actionTypesPost.POST_OTHER_CHANGE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypesPost.POST_OTHER_CHANGE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          error: null
        });
      }
      return state;
    case actionTypesPost.POST_OTHER_CHANGE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypesPost.POST_OTHER_CHANGE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypesPost.POST_OTHER_CHANGE_RESET:
      return baseInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


