export const UPDATE_CODE = 'UPDATE_CODE';
/* error */
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const RESET_ERROR = 'RESET_ERROR';

/* auth */
export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_LOGOUT = 'LOGOUT_LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const LOGIN_UPDATE_TOKEN = 'LOGIN_UPDATE_TOKEN';
export const LOGIN_SET_REDIRECT = 'LOGIN_SET_REDIRECT';

/*
* 获取GET_CLOUD_STS
* */
export const GET_CLOUD_STS_LOAD = 'GET_CLOUD_STS_LOAD';
export const GET_CLOUD_STS_REQUEST = 'GET_CLOUD_STS_REQUEST';
export const GET_CLOUD_STS_SUCCESS = 'GET_CLOUD_STS_SUCCESS';
export const GET_CLOUD_STS_FAILURE = 'GET_CLOUD_STS_FAILURE';
export const GET_CLOUD_STS_UPDATE = 'GET_CLOUD_STS_UPDATE';
export const GET_CLOUD_STS_RESET = 'GET_CLOUD_STS_RESET';

/*
* 获取验证码
* */
export const GET_PHONE_CODE_LOAD = 'GET_PHONE_CODE_LOAD';
export const GET_PHONE_CODE_REQUEST = 'GET_PHONE_CODE_REQUEST';
export const GET_PHONE_CODE_SUCCESS = 'GET_PHONE_CODE_SUCCESS';
export const GET_PHONE_CODE_FAILURE = 'GET_PHONE_CODE_FAILURE';
export const GET_PHONE_CODE_UPDATE = 'GET_PHONE_CODE_UPDATE';
export const GET_PHONE_CODE_RESET = 'GET_PHONE_CODE_RESET';


/*
* 个人信息
* */
export const GET_MY_INFO_LOAD = 'GET_MY_INFO_LOAD';
export const GET_MY_INFO_REQUEST = 'GET_MY_INFO_REQUEST';
export const GET_MY_INFO_SUCCESS = 'GET_MY_INFO_SUCCESS';
export const GET_MY_INFO_FAILURE = 'GET_MY_INFO_FAILURE';
export const GET_MY_INFO_UPDATE = 'GET_MY_INFO_UPDATE';
export const GET_MY_INFO_RESET = 'GET_MY_INFO_RESET';

/*
* 修改个人信息
* */
export const MODIFY_MY_INFO_LOAD = 'MODIFY_MY_INFO_LOAD';
export const MODIFY_MY_INFO_REQUEST = 'MODIFY_MY_INFO_REQUEST';
export const MODIFY_MY_INFO_SUCCESS = 'MODIFY_MY_INFO_SUCCESS';
export const MODIFY_MY_INFO_FAILURE = 'MODIFY_MY_INFO_FAILURE';
export const MODIFY_MY_INFO_UPDATE = 'MODIFY_MY_INFO_UPDATE';
export const MODIFY_MY_INFO_RESET = 'MODIFY_MY_INFO_RESET';


/*
* 获取机构信息
* */
export const GET_ORG_INFO_LOAD = 'GET_ORG_INFO_LOAD';
export const GET_ORG_INFO_REQUEST = 'GET_ORG_INFO_REQUEST';
export const GET_ORG_INFO_SUCCESS = 'GET_ORG_INFO_SUCCESS';
export const GET_ORG_INFO_FAILURE = 'GET_ORG_INFO_FAILURE';
export const GET_ORG_INFO_UPDATE = 'GET_ORG_INFO_UPDATE';
export const GET_ORG_INFO_RESET = 'GET_ORG_INFO_RESET';

/*
* 修改机构信息
* */
export const MODIFY_ORG_INFO_LOAD = 'MODIFY_ORG_INFO_LOAD';
export const MODIFY_ORG_INFO_REQUEST = 'MODIFY_ORG_INFO_REQUEST';
export const MODIFY_ORG_INFO_SUCCESS = 'MODIFY_ORG_INFO_SUCCESS';
export const MODIFY_ORG_INFO_FAILURE = 'MODIFY_ORG_INFO_FAILURE';
export const MODIFY_ORG_INFO_UPDATE = 'MODIFY_ORG_INFO_UPDATE';
export const MODIFY_ORG_INFO_RESET = 'MODIFY_ORG_INFO_RESET';

/*
* 用户信息
* */
export const GET_USER_INFO_LOAD = 'GET_USER_INFO_LOAD';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_UPDATE = 'GET_USER_INFO_UPDATE';
export const GET_USER_INFO_RESET = 'GET_USER_INFO_RESET';


/*
* 用户信息认证
* */
export const CERTIFY_USER_INFO_LOAD = 'CERTIFY_USER_INFO_LOAD';
export const CERTIFY_USER_INFO_REQUEST = 'CERTIFY_USER_INFO_REQUEST';
export const CERTIFY_USER_INFO_SUCCESS = 'CERTIFY_USER_INFO_SUCCESS';
export const CERTIFY_USER_INFO_FAILURE = 'CERTIFY_USER_INFO_FAILURE';
export const CERTIFY_USER_INFO_UPDATE = 'CERTIFY_USER_INFO_UPDATE';
export const CERTIFY_USER_INFO_RESET = 'CERTIFY_USER_INFO_RESET';

/*
* 获取地区表
* */
export const GET_AREA_LIST_LOAD = 'GET_AREA_LIST_LOAD';
export const GET_AREA_LIST_REQUEST = 'GET_AREA_LIST_REQUEST';
export const GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS';
export const GET_AREA_LIST_FAILURE = 'GET_AREA_LIST_FAILURE';
export const GET_AREA_LIST_UPDATE = 'GET_AREA_LIST_UPDATE';
export const GET_AREA_LIST_RESET = 'GET_AREA_LIST_RESET';

/*
* 获取钱包信息
* */
export const GET_WALLET_LOAD = 'GET_WALLET_LOAD';
export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'GET_WALLET_FAILURE';
export const GET_WALLET_UPDATE = 'GET_WALLET_UPDATE';
export const GET_WALLET_RESET = 'GET_WALLET_RESET';

/*
* 获取收货地址
* */
export const GET_ADDRESS_LIST_LOAD = 'GET_ADDRESS_LIST_LOAD';
export const GET_ADDRESS_LIST_REQUEST = 'GET_ADDRESS_LIST_REQUEST';
export const GET_ADDRESS_LIST_SUCCESS = 'GET_ADDRESS_LIST_SUCCESS';
export const GET_ADDRESS_LIST_FAILURE = 'GET_ADDRESS_LIST_FAILURE';
export const GET_ADDRESS_LIST_UPDATE = 'GET_ADDRESS_LIST_UPDATE';
export const GET_ADDRESS_LIST_RESET = 'GET_ADDRESS_LIST_RESET';

/*
* 获取收货地址
* */
export const GET_ADDRESS_LOAD = 'GET_ADDRESS_LOAD';
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';
export const GET_ADDRESS_UPDATE = 'GET_ADDRESS_UPDATE';
export const GET_ADDRESS_RESET = 'GET_ADDRESS_RESET';


/*
* 设置收货地址
* */
export const SET_ADDRESS_LOAD = 'SET_ADDRESS_LOAD';
export const SET_ADDRESS_REQUEST = 'SET_ADDRESS_REQUEST';
export const SET_ADDRESS_SUCCESS = 'SET_ADDRESS_SUCCESS';
export const SET_ADDRESS_FAILURE = 'SET_ADDRESS_FAILURE';
export const SET_ADDRESS_UPDATE = 'SET_ADDRESS_UPDATE';
export const SET_ADDRESS_RESET = 'SET_ADDRESS_RESET';
