/*
* banner
* */
export const GET_AD_BANNER_LOAD = 'GET_AD_BANNER_LOAD';
export const GET_AD_BANNER_REQUEST = 'GET_AD_BANNER_REQUEST';
export const GET_AD_BANNER_SUCCESS = 'GET_AD_BANNER_SUCCESS';
export const GET_AD_BANNER_FAILURE = 'GET_AD_BANNER_FAILURE';
export const GET_AD_BANNER_UPDATE = 'GET_AD_BANNER_UPDATE';
export const GET_AD_BANNER_RESET = 'GET_AD_BANNER_RESET';
/*
* hot
* */
export const GET_AD_INDEX_HOT_LOAD = 'GET_AD_INDEX_HOT_LOAD';
export const GET_AD_INDEX_HOT_REQUEST = 'GET_AD_INDEX_HOT_REQUEST';
export const GET_AD_INDEX_HOT_SUCCESS = 'GET_AD_INDEX_HOT_SUCCESS';
export const GET_AD_INDEX_HOT_FAILURE = 'GET_AD_INDEX_HOT_FAILURE';
export const GET_AD_INDEX_HOT_UPDATE = 'GET_AD_INDEX_HOT_UPDATE';
export const GET_AD_INDEX_HOT_RESET = 'GET_AD_INDEX_HOT_RESET';
/*
* hot
* */
export const GET_AD_INDEX_ITEM_HOT_LOAD = 'GET_AD_INDEX_ITEM_HOT_LOAD';
export const GET_AD_INDEX_ITEM_HOT_REQUEST = 'GET_AD_INDEX_ITEM_HOT_REQUEST';
export const GET_AD_INDEX_ITEM_HOT_SUCCESS = 'GET_AD_INDEX_ITEM_HOT_SUCCESS';
export const GET_AD_INDEX_ITEM_HOT_FAILURE = 'GET_AD_INDEX_ITEM_HOT_FAILURE';
export const GET_AD_INDEX_ITEM_HOT_UPDATE = 'GET_AD_INDEX_ITEM_HOT_UPDATE';
export const GET_AD_INDEX_ITEM_HOT_RESET = 'GET_AD_INDEX_ITEM_HOT_RESET';
