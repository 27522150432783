import { combineReducers } from "redux"
import darkMode from "./reducers/darkMode"
import * as User from "./reducers/user"
import * as Ad from "./reducers/ad"
import * as Base from "./reducers/base"
import * as Shop from "./reducers/shop"
import * as Order from "./reducers/order"
import * as Relation from "./reducers/relation"
import * as Market from "./reducers/market"
import * as Post from "./reducers/post"

export default combineReducers({ ...User, ...Ad, ...Base, ...Post, ...Shop, ...Order, ...Relation, ...Market })
