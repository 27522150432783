import { normalize } from 'normalizr'
import { camelizeKeys } from 'humps'
import 'isomorphic-fetch'
import { API_VERSION, API_ROOT } from '../../config'


import { UPDATE_CODE } from '../constants/ActionType'
import { sign, decrypt } from '../../libs/cryptoServer'
/**
 * 服务器交接
 * @returns {Promise<Response | never | never>}
 * @param endpoint
 * @param schema
 * @param param
 */
// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(fullUrl, schema, param) {
  param = param || {};

  // console.log(fullUrl);

  return fetch(fullUrl, param)
      .then(response =>{
        // console.log(response);
            if(response.status === 204){
              return Promise.resolve({json:{},response})
            }else{
              if(param.resType === 'resType' ) {
                return response.text().then(text => ({ json: { text }, response }))
              } else {
                return response.json().then(json => ({ json, response }))
              }
            }
      }).then(({ json, response }) => {
        // console.log(json)
        // console.log(json);
        try {
          if (!response.ok) {
            return Promise.reject({ ...json, code:response.status })
          }
          if(json.error) {
            return Promise.reject(json);
          }

          // json.data = decrypt(json.data);

          const camelizedJson = camelizeKeys(json);

          if(param.isFile) {
            return camelizedJson.data;
          }

          if(param.schema) {
            const ress = Object.assign({}, normalize((camelizedJson.data || {}), schema),
              //camelizedJson
            );

            console.log(ress);

            return ress;
          }
          return camelizedJson.data;
        } catch (error) {
          return Promise.reject(error)
        }
      })
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.



// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API')

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }
  let { endpoint } = callAPI
  const { schema, types } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  // if (!schema) {
  //   throw new Error('Specify one of the exported Schemas.')
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }
  let param;
  /*param = Object.assign({
        headers:{
          "Content-Type":"application/x-www-form-urlencoded"
        }
      },
      {method:'post'},
      {body:JSON.stringify(
      {
        body:action.param.body,
        head:{
          userToken:store.getState().auth.get('userToken'),
          time:Date.now()
        }
      })});*/

  endpoint = API_VERSION +  endpoint;
  endpoint = (endpoint.indexOf('http') !== 0) ? API_ROOT + endpoint : endpoint;

  if(action.param){

    param = action.param || {};
    param.timeout = 30000;
    param.headers = {
      "Content-type": "application/json",
      "authorization" : store.getState().auth.get('userToken'),
      "x-client-code" : store.getState().auth.get('clientId'),
      ...param.headers
    };
    if(!store.getState().auth.get('userToken')) {
      delete param.headers['authorization'];
    }


    if(param.method && param.method.toUpperCase() === 'POST') {
      if(param.isFile) {
        delete param.headers['Content-type'];
        param.body = param.body;
      } else {
        const keys = Object.keys(param.body || {});
        const newBody = {};
        keys.sort().map((item) => {
          newBody[item] = param.body[item];
        });
        param.body = JSON.stringify(newBody);
        const signStr = sign('POST', endpoint, param.headers['authorization'] ? {authorization: param.headers['authorization']} : {} , {data: param.body});
        param.headers['sign'] = signStr.sign;
        param.headers['timestamp'] = signStr.timestamp;
        param.headers['randomStr'] = signStr.randomStr;
      }
    } else {
      const signStr = sign('GET', endpoint, param.headers['authorization'] ? {authorization: param.headers['authorization']} : {} , {});
      param.headers['sign'] = signStr.sign;
      param.headers['timestamp'] = signStr.timestamp;
      param.headers['randomStr'] = signStr.randomStr;
    }
  };

  // return;
  /*const form = new FormData();
  form.append('body',action.param.body);
  form.append('head',{
    userToken:store.getState().auth.get('userToken'),
    time:Date.now()});
  const param = Object({},
      {method:'post',
        body:form
      }
  );*/
  const [ requestType, successType, failureType ] = types;
  function actionWith(data) {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction
  }
  next(actionWith({ type: requestType }));
  return callApi(endpoint, schema, param).then(
    response => {
      if (response && response.code) {
        next({
          type: UPDATE_CODE,
          param: {
            code: response.code
          }
        })
      }
      next(actionWith({
        response,
        lastFetched: Date.now(),
        type: successType
      }))
    },
    error =>
      next(actionWith({
        type: failureType,
        error: {
          errorCode:!error.code?500:error.code,
          message:error.message
        }
      }))
  )
}
