import { createStore, compose, applyMiddleware } from "redux"
import reducers from "./reducers"
import thunk from 'redux-thunk'
import callApi from './middleware/callApi'
import { createLogger } from 'redux-logger'

import config from '../config'

// Configure the store
export default function configureStore(initialState = {}) {
  const store = createStore(
    reducers,
    initialState,

    compose(
      config.DEV ?
      applyMiddleware(thunk, createLogger(),callApi) :
      applyMiddleware(thunk,callApi),
    )
  )

  return store
}
