import md5 from 'js-md5'
import CryptoJS from 'crypto-js'
import randomstring from 'randomstring';

import { getUrl, isArray, isObject } from './util'


export const sign = (method, endpoint, headers = {}, body = {}) => {
  const timestamp = new Date().getTime();
  const randomStr = randomstring.generate(32);

  let params = {timestamp, randomStr, ...headers};

  if(method.toUpperCase() === 'POST') {
    Object.assign(params, headers, body);
    delete params.sign;
  } else {
    const urlParams = getUrl(endpoint);
    Object.assign(params, headers);
    urlParams.map((item) => {
      if(item && (urlParams[item] !== '')) {
        params[item] = encodeURI(urlParams[item]);
      }
    });
    delete params.sign;
  }

  let paramStrs = [];

  Object.keys(params).map((item) => {
    if(typeof params[item] === 'object'){
      paramStrs.push(item + JSON.stringify(params[item]));
    } else {
      paramStrs.push(item + params[item]);
    }
  });

  paramStrs.sort();

  const singStr = paramStrs.join('').replace(/\'/g, "");

  let confirmKey = '';

  let md1 = md5(confirmKey + singStr);


  if (md1) {
    let md2 = md5(md1.substring(0, 5) + md1.substring(md1.length - 5, md1.length));
    return {
      timestamp,
      randomStr,
      sign: md2.toUpperCase(),
    }
  }
};



export const decrypt = (text) => {
  const key = text.slice(0, 16);
  // const key = CryptoJS.enc.Hex.parse();

  const dataText = text.slice(16);

  const bytes  = CryptoJS.AES.decrypt(dataText, key, {
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding
  });
  let originalText = bytes.toString(CryptoJS.enc.Utf8);

  const jsonString = originalText.match(/[\[\{].*[\[\]\{\}]/);

  if(jsonString) {
    originalText = JSON.parse(jsonString && jsonString[0]);
  }
  return originalText;
};


export const encrypt = (text) => {

  const randomStr = randomstring.generate(16);

  const ciphertext = CryptoJS.AES.encrypt(text, randomStr, {
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding
  }).toString();

  return randomStr + ciphertext;

};
